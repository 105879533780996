/** Tailwind must be the first import **/
@import "settings/_settings.tailwind";

@import "react-loading-skeleton/dist/skeleton.css";

@import "settings/_settings.colors";
@import "settings/_settings.common";
@import "settings/_settings.fonts";

.contenedor {
  width: 125%;
}

@media only screen and (min-width: 500px) {
  .contenedor {
    width: 100%;
  }
}

.live-shopping .spck-list {
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 900px) {
  .live-shopping {
    padding-top: 20px;
  }
}

.grecaptcha-badge {
  display: none !important;
}

@media only screen and (min-width: 1024px) {
  .header-bag {
    width: 24px;
    height: 24px;
  }
}

/* Pagination pointer fix */
.pagination-number a {
  cursor: pointer;
}

/* Hide arrows in input tel Chrome*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in input tel Firefox*/
input[type="number"] {
  -moz-appearance: textfield;
}

/* Apply styles to cookies settings button */
#ccc button.ccc-notify-button.ccc-notify-link {
  @apply bg-[#ff594c] !border-2 !border-[#ff594c] !border-solid no-underline;
}

#ccc button.ccc-notify-button.ccc-notify-link span {
  @apply !text-white;
}
